/**
 * 
 */

define( 'Fullscreen',[ 'jquery' ], function ( $ ) {
  
  var Fullscreen = {
    request: function( element ) {
      if( element.requestFullscreen ) {
        element.requestFullscreen();
        return true;
      } else if( element.msRequestFullscreen ) {
        element.msRequestFullscreen();
        return true;
      } else if( element.mozRequestFullScreen ) {
        element.mozRequestFullScreen();
        return true;
      } else if( element.webkitRequestFullscreen ) {
        element.webkitRequestFullscreen();
        return true;
      }
      
      return false;
    },
    isFullscreen: function() {
      if( document.fullscreenElement || document.msFullscreenElement ||
          document.mozFullScreenElement || document.webkitFullscreenElement ) {
        return true;
      }
      return false;
    },
    exit: function() {
      if( document.exitFullscreen ) {
        document.exitFullscreen();
        return true;
      } else if( document.msExitFullscreen ) {
        document.msExitFullscreen();
        return true;
      } else if( document.mozCancelFullScreen ) {
        document.mozCancelFullScreen();
        return true;
      } else if( document.webkitExitFullscreen ) {
        document.webkitExitFullscreen();
        return true;
      }
      
      return false;
    }
  };
  
  $(document).on(
      'webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange', 
      function() { $( Fullscreen ).trigger( 'fullscreenchange' ); } );
  
  return Fullscreen;
} );

