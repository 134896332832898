define( 'app/app',['require','jquery','threejs','RaycastSelector','Panel','Scene','Atlas','Button','Dialog','Fullscreen','ProgressBar'],function( require ) {
  var $=require('jquery');
  var THREE=require('threejs');
  var RaycastSelector = require( 'RaycastSelector' );
  
  var Panel = require( 'Panel' );
  var Scene = require( 'Scene' );
  var Atlas = require( 'Atlas' );
  var Button = require( 'Button' );
  var Dialog = require( 'Dialog' );
  var Fullscreen = require( 'Fullscreen' );
  var ProgressBar = require( 'ProgressBar' );
  
  $(function() {
    var webGLContainer = $( 'div#webglcontainer' );
    
    var scene = new Scene( webGLContainer );
    window.addEventListener( 'beforeunload', function( event ) { scene.dispose(); } );
    
    $( window ).on( 'resize', function() { scene.resizeCanvas(); } );
    
    scene.resizeCanvas();
    $( 'div.nowebgl' ).hide();
    
    var atlas = new Atlas( scene );
    var loadingProgress = new ProgressBar();
    $(document).bind( 'atlas.loadProgress', 
        function( event, progress) {
          if( progress.loaded >= (progress.total - 1) ) {
            loadingProgress.close();
          } else {
            loadingProgress.open( "Loading airway tree...", progress.total );
          
            loadingProgress.update( progress.loaded );
          }
        }
    );
    
    var panel = new Panel( 'div#panel' );

    function render() { 
      requestAnimationFrame(render); 
      scene.render();
    }
    render();
    
    var selection = new RaycastSelector( scene );
    
    currentDataset = 0;
    contourVariable = 'none';
    contourValues = {};
    contourSettings = { 'min': 0, 'max': 1 };
    
    function setContours( variable ) {
      if( variable !== undefined ) {
        contourVariable = variable;
      }
      
      if( contourVariable === 'none' ) {
        contourValues = {};
        updateAllContours();
      } else {
        $.ajax( "/api/contour/" + currentDataset + "/" + contourVariable, {
            dataType: 'json',
            success: function( data, status, jqXHR ) {
              if( data.values.length === 0 ) return;
              
              contourValues = {};
              contourSettings.min = data.values[ 0 ][ 1 ];
              contourSettings.max = contourSettings.min;
              
              
              data.values.forEach( function( value ) {
                contourSettings.min = Math.min( contourSettings.min, value[ 1 ] );
                contourSettings.max = Math.max( contourSettings.max, value[ 1 ] );
                
                contourValues[ value[ 0 ] ] = value[ 1 ];
              } );
              
              updateAllContours();
            }
              
          } );
      }
    }
    
    function updateContour( airway ) {
      airwayId = airway.userData.airway;
      if( contourValues[ airwayId ] === undefined ) {
        airway.material = scene.material;
      } else {
        var scaledValue = (contourValues[ airwayId ] - contourSettings.min) / contourSettings.max;
        scaledValue = Math.min( 1, Math.max( 0, scaledValue ) );
        var level = Math.floor( scaledValue * (scene.contours.length - 1) );
        
        if( scene.contourMaterials[ level ] !== undefined ) {
          airway.material = scene.contourMaterials[ level ];
        }
      }
      
      scene.update();
    }
    
    function updateAllContours() {
      scene.airwayRoot.traverse( updateContour );
      
      selection.selection.forEach( function( selectInfo ) {
        selectInfo.object.material = scene.selectionMaterials[ selectInfo.index ];
      } );
    }
    
    function openPanel( airway, index ) {
      $.ajax( "/api/panel", {
          data: { dataset: currentDataset, id: airway },
          dataType: 'json',
          success: function( data, status, jqXHR ) {
            panel.update( index, scene.selectionColors[ index ], data );
            panel.show();
          }
      } );
    }
    
    function setOpacities( opacity ) {
      scene.material.opacity = opacity;
      scene.contourMaterials.forEach( function( material ) {
        material.opacity = opacity;
      });
    }
    
    selection.onSelect = function( object, index ) {
      setOpacities( 0.5 );
      object.material = scene.selectionMaterials[ index ];
      scene.update();
      openPanel( object.userData.airway, index );
      return true;
    };
    selection.onDeselect = function( object, index  ) {
      panel.remove( index );
      updateContour( object );
      scene.update();
      return true;
    };
    selection.onClear = function() {
      setOpacities( 1.0 );
      panel.hide();
      
      return true;
    };
    
    function rowClick( element, dialog ) {
      var atlasId = $( element ).attr( 'atlasId' );
      var datasetId = $( element ).attr( 'datasetId' );
      
      if( atlasId != atlas.atlasId ) {
        atlas.load( atlasId );
      }
      currentDataset = datasetId;
      
      setContours();
      selection.clear();
      
      dialog.close();
    }
    
    
    var openDialog = new Dialog( 'open' );
    openDialog.button.addTo( $( '#sidemenu' ) );
    openDialog.content( $.parseHTML( 
      '<div><input value="Search" class="search"></div>' +
      '<div id="results"></div>' ) );

    openDialog.hasOpened = function() {
      $.ajax( '/api/datasets', {
        dataType: 'html',
        success: function( html ) {
          openDialog.content().children( '#results' ).html( html );
          openDialog.content().find( 'div.datasetRow' ).on( 'click', function() {
            rowClick( this, openDialog );
          });
        }
      } );
    };
    
    var fullScreenToggle = new Button( 'fullScreen' );
    fullScreenToggle.addTo( $( '#sidemenu' ) );
    $( Fullscreen ).on( 'fullscreenchange', function() {
      fullScreenToggle.checked( Fullscreen.isFullscreen() ); 
    } );
    
    $( fullScreenToggle ).on( 'buttonClick', function() {
      if( !fullScreenToggle.checked() ) {
        Fullscreen.request( $( 'body' )[ 0 ] );
      } else {
        Fullscreen.exit();
      }  
    } );
    
    var contourDialog = new Dialog( 'contour' );
    contourDialog.button.addTo( $( '#sidemenu' ) );
    var contourVars = $( "<div />", { 'id': 'results' } );
    var contourForm = $( "<form />" );
    contourForm.appendTo( contourVars );
    function addVariable( name, internalId ) {
      var container = $( "<div />" );
      var radio = $( "<input />", { 'type': 'radio', 'name': 'contour' } );
      radio.on( 'click', function() { 
        setContours( internalId );
        contourDialog.close();
      } );
      container.append( radio );
      container.append( name );
      container.appendTo( contourForm );
    }
    addVariable( "None", 'none' );
    addVariable( "Maximum Flow", "flow" );
    addVariable( "Maximum Volume", "volume" );
    
    contourDialog.content( contourVars  );
    
    
  });
} );

