/**
 * 
 */

define( 'Panel',['flot','jquery'], function( flot, $ ) {
  
  function Panel( domNode ) {
    this.root = $(domNode);
    
    this.plotContainer  = this.root.find( 'div#plot' );
    this.plot = undefined;
    this.generation = this.root.find( 'td#generation' );
    
    this.tabcontainer = this.root.find( 'div#plottabs' );
    
    this.labelX = this.root.find( 'div.xlabel' );
    this.labelY = this.root.find( 'div.ylabel' );
    
    this.data = [];
    
    var self = this;
    this.tabcontainer.children().on( 'click', function( event ) { self.clickEvent( this, event ); } );
    
    this.root.find( 'div.close' ).on( 'click', function( event ) { self.hide(); } );
    
    $( window ).on( 'resize', function( event ) { self.resizeEvent( event ); } );
  }
  
  Panel.prototype = {
      clickEvent: function( element, event ) {
        if( $(element).hasClass( 'plottab' ) ) {
          this.tabcontainer.children( '.selected' ).removeClass( 'selected' );
          $(element).addClass( 'selected' );
        }
        this.updatePlot();
      },
      resizePlotArea: function() {
        var availableHeight = this.root.innerHeight() - this.plotContainer.position().top;
        
        // We can't rely on the value of plotContainer.innerWidth as we are overriding it, 
        //  instead, use the tab container's width as a proxy for it
        var availableWidth = this.tabcontainer.innerWidth();
        
        function min( x, y ) { return (x<y)? x : y; }
        
        var squareSize = min( availableHeight, availableWidth );
        
        this.plotContainer.css( 'width', squareSize );
        this.plotContainer.css( 'height', squareSize );
      },
      resizeEvent: function( event ) {
        this.resizePlotArea();
        
        if( this.plot !== undefined ) {
          this.plot.resize();
          
          this.plot.setupGrid();
          this.plot.draw();
          
          var options = this.plot.getOptions();

          this.labelY.css( 'bottom', options.grid.margin.bottom );
          this.labelY.css( 'width', this.plot.height()  );
        }
      },
      update: function( slot, color, jsonData ) {
        this.show();
        
        while( this.data.length < slot ) this.data.push( undefined );
        
        this.data[ slot ] = { 'color': color, 'data': jsonData };
        
        this.generation.html( this.data.generation );
        
        this.updatePlot();
      },
      remove: function( slot ) {
        if( slot >= this.data.length ) {
          return;
        }
        
        this.data[ slot ] = undefined;
        
        isEmpty = true;
        for( var index = 0; index < this.data.length; ++index ) {
          if( this.data[ index ] !== undefined ) {
            isEmpty = false;
            break;
          }
        }
        if( isEmpty ) {
          this.hide();
        } else {
          this.updatePlot();
        }
      },
      updatePlot: function() {
        var currentTab = this.selected();
        
        var plotData = [];
        
        var labelX = "Time (frames)";
        var labelY = "";
        
        for( var seriesIndex = 0; seriesIndex < this.data.length; ++seriesIndex ) {
          var series = this.data[ seriesIndex ];
          if( series === undefined ) continue;
          
          values = [];
          if( currentTab == 'flow' ) {
            values = series.data.flow;
            labelY = "Flow (vx/fr)";
          } else if( currentTab == 'volume' ) {
            values = series.data.volume;
            labelY = "Volume (vx/fr)";
          } else if( currentTab == 'fv' ) {
            values.push( [ 0, 0 ] );
            for( i = 0; i < series.data.flow.length; ++i ) {
              values.push( [ series.data.volume[ i ][ 1 ], series.data.flow[ i ][ 1 ] ] );
            }
            values.push( [ 0, 0 ] );
            
            labelX = "Volume (vx/fr)";
            labelY = "Flow (vx/fr)";
          }
          
          plotData.push( { 
            'color': '#' + series.color.toString( 16 ), 
            'lines': {
              'lineWidth': 3.0
            },
            'data': values } );
        }
        
        // Trigger resize event to ensure correct size
        this.resizePlotArea();
        
        this.plot = $.plot( this.plotContainer, plotData,
            {
              grid: {
                color: "#ffffff",
                tickColor: "#ffffff",
                margin: {
                  left: 20,
                  bottom: 20,
                  top: 8
                }
              }
            });
        
        
        this.labelX = $( '<div class="xlabel"></div>' ).appendTo( this.plotContainer );
        this.labelY = $( '<div class="ylabel"></div>' ).appendTo( this.plotContainer );
        
        this.labelX.text( labelX );
        this.labelY.text( labelY );
        
        this.resizeEvent();
        
      },
      selected: function() {
        var results = this.tabcontainer.find( '.selected' );
        
        if( results ) {
          return results.attr( 'id' );
        } else {
          return undefined;
        }
      },
      show: function() { this.root.fadeIn(); },
      hide: function() { this.root.fadeOut(); }
  };
  
  return Panel;
} 
);

