/**
 * 
 */

define( 'Dialog',[ 'jquery', 'Button' ], function ( $, Button ) {
  function Dialog( id ) {
    this.dialog = $( $.parseHTML( '<div class="dialog" id="' + id + '"></div>' ) );
    this.button = new Button( id );
    
    this.dialog.appendTo( $( document.body ) );
    
    this.name = '';
    
    $( this.button ).on( 'buttonClick', this.toggleOpen.bind( this ) );
  }
  
  Dialog.prototype = {    
    close: function() {
      this.button.checked( false );
      
      this.dialog.hide();
    },
    
    content: function( domNodes ) {
      if( domNodes === undefined ) {
        return this.dialog; 
      } else {
        this.dialog.append( domNodes );
      }
    },
    
    open: function() {
      this.button.checked( true );
      
      this.dialog.show();
      
      this.dialog.css( 'left', this.button.html.outerWidth() );
      this.dialog.css( 'top', this.button.html.offset().top );
      
      this.hasOpened();
    },
    
    hasOpened: function() {},
    
    toggleOpen : function() {
      var checked = this.button.checked();
      
      if( !checked ) {
        this.open();
      } else {
        this.close();
      }
    }
  };
  
  return Dialog;
});

