/**
 * 
 */

define( 'Button',[ 'jquery' ], function ( $ ) {
  function Button( id ) {
    this.html = $( $.parseHTML( '<div class="button" id="' + id + '"><a href="#"></a></div>' ) );
    
    this.name = '';
    
    var handleClick = function() {
      $( this ).trigger( 'buttonClick', [ this, this.checked() ] );
    };
    
    this.html.find( 'a' ).click( handleClick.bind( this ) );

  }
  
  Button.prototype = {
    addTo: function( toolbar ) {
      this.html.appendTo( toolbar );
    },
    
    checked: function( isChecked ) {
      if( isChecked === undefined ) {
        return this.html.hasClass( 'checked' );
      } else {
        return this.html.toggleClass( 'checked', isChecked ).hasClass( 'checked' );
      }
    },
      
    name : function( name ) {
      if( name === undefined ) {
        return this.name;
      } else {
        this.name = name;
        this.html.children( 'a' ).text( name );
      }
    },
    
    tooltip : function( tooltip ) {
      
    },
    
    toggleChecked: function() {
      return this.html.toggleClass( 'checked' ).hasClass( 'checked' );
    }

  };
  
  return Button;
});

