/**
 * 
 */

define( 'RaycastSelector',['threejs' ], function( THREE ) {
  
  // Maximum distance between mouseup and mousedown before the operation
  // is considered to be a drag (rather than a selection)
  var kDragDistance = 9; 
  
  function RaycastSelector( sceneGraph ) {
    this.sg = sceneGraph;
    
    var self = this;
    this.lastX = -1;
    this.lastY = -1;
    this.sg.container.on( 'mousedown', 
        function( event ) { self.mousedown( event ); } );
    this.sg.container.on( 'mouseup',
        function( event ) { self.mouseup( event ); } );
    
    this.raycaster = new THREE.Raycaster();
    this.projector = new THREE.Projector();
  
    this.selection = Array();   
    this.selectionIndices = [ 0, 1, 2 ];
    this.maxSelections = 3;
    
    
    this.isSelectable = function( object ) { return true; };
    this.onSelect     = function( object ) { return true; };
    this.onDeselect   = function( object ) { return false; };
    this.onClear      = function( object ) { return true; };
  }
  
  RaycastSelector.prototype = {
    clear: function() {
      while( this.selection.length > 0 ) {
        var selection = this.selection.shift();
        this.deselect( selection );
      }
      this.onClear();
    },
  
    deselect: function( selection ) {
      this.onDeselect( selection.object, selection.index );
      this.selectionIndices.push( selection.index );
    },
    
    select: function( object ) {
      while( this.selection.length >= this.maxSelections || this.maxSelections < 1 ) {          
        this.deselect( this.selection.shift() );
      }
      
      selection = { 'object': object, 'index': 0 };
      
      selection.index = this.selectionIndices.pop();
      this.selection.push( selection );
      
      this.onSelect( selection.object, selection.index );
    },
      
    mousedown: function( event ) {
      this.lastX = event.clientX;
      this.lastY = event.clientY;
    }, 
  
    mouseup: function( event ) {
      var distX = event.clientX - this.lastX;
      var distY = event.clientY - this.lastY;
      
      var buttons = (1 << event.button) | ( (event.buttons !== undefined) ? event.buttons : 0 );
      
      if( buttons != 1 ) {
        // Only respond to left click (or equivalent)
        return;
      }
      
      if( (distX * distX + distY * distY) > kDragDistance ) {
        return;
      }
      
      var directionVector = new THREE.Vector3(
         event.clientX / this.sg.container.width() * 2 - 1,
        -(event.clientY / this.sg.container.height() * 2 - 1),
         1 );
    
      this.projector.unprojectVector( directionVector, this.sg.camera );
      directionVector.sub( this.sg.camera.position );
      directionVector.normalize();
    
      this.raycaster.set( this.sg.camera.position, directionVector );
    
      var intersectingObjects = this.raycaster.intersectObjects( this.sg.scene.children, true );
    
      if( intersectingObjects.length > 0 ) {
        var selected = intersectingObjects[ 0 ];
        var selectedObject = selected.object;
        
        toggled = false;
        
        for( var selectionIndex = 0; selectionIndex < this.selection.length; ++selectionIndex ) {
          if( this.selection[ selectionIndex ].object.id === selectedObject.id ) {
            this.deselect( this.selection[ selectionIndex ] );
            this.selection.splice( selectionIndex, 1 );
            toggled = true;
            break;
          }
        }
        
        if( this.isSelectable( selectedObject ) && !toggled ) {
          this.select( selectedObject );
        }        
      } else {
        this.clear();
      }
    }
    
  };
  
  return RaycastSelector;
});
  
