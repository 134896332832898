/**
 * 
 */

define( 'Atlas',['threejs', 'jquery' ], function( THREE, $ ) {
  function Atlas( scene ) {
    this.scene = scene;
    this.atlasId = null;
    this.atlasSize = 0;
    
    this.numLoaded = 0;
        
    // Create a websocket
    var wsProtocol = (document.location.protocol.indexOf("https:") === 0) ? "wss://" : "ws://";
    var wsEndPoint = wsProtocol + window.location.host + "/api/atlasstream";
    
    this.websocket = new WebSocket( wsEndPoint );
    this.websocket.binaryType = 'arraybuffer';

    //websocket.onopen = function( event ) { beginRequest( event ); };
    //websocket.onclose = function( event ) { scene.update(); };
    this.websocket.onmessage = function( event ) { this.parseMessage( event ); }.bind( this );
  
    this.load = function( atlasId ) {
      this.lastUpdate = 0;
      
      request = { atlasId: atlasId };
      
      $.ajax( '/api/atlas', {
          context: this,
          success: this.beginLoad,
          data: request
        }
      );
    };
    
    this.notifyStatus = function() {
      $(document).trigger( 'atlas.loadProgress', { loaded: this.numLoaded, total: this.atlasSize } );
    };
    
    this.beginLoad = function( atlasInfo ) {
      this.atlasSize = atlasInfo.numSegments;
      this.atlasId = atlasInfo.id;
      this.numLoaded = 1;
      
      this.notifyStatus();
      
      this.scene.dispose();
      
      this.websocket.send( JSON.stringify( atlasInfo ) );
    };
      
    this.parseMessage = function( event ) {
      
      //scene.loadJSON( JSON.parse( event.data ) );
      
      this.scene.loadBinary( event.data );
      this.numLoaded += 1;
      
      if( this.numLoaded > (this.lastUpdate + this.atlasSize / 10) || this.numLoaded >= this.atlasSize ) {
        this.scene.update();
        
        this.lastUpdate = this.numLoaded;
      }

      this.notifyStatus();
    };
  }
  
  return Atlas;
} );
