/**
 * 
 */
define( 'Scene',['threejs','jquery', 'OrbitControls' ], function( THREE, $, OrbitControls ) {
  
  THREE.OrbitControls = OrbitControls;

  function Scene( container ) {
    this.container = container; 
    
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 10000 ); 
  
    this.controls = new THREE.OrbitControls( this.camera, container.get( 0 ) );
    this.controls.addEventListener( 'change', function() { this.update(); }.bind( this ) );
    
    this.renderer = new THREE.WebGLRenderer();
    
    container.get( 0 ).appendChild( this.renderer.domElement );
    
    this.scene.fog = new THREE.Fog( 0x808080, 2000, 4000 );
  
    // LIGHTS
    var ambientLight = new THREE.AmbientLight( 0x222222 );
    this.scene.add(ambientLight);

    for( var y = -1; y <= 1; y +=2 ) {
      for ( var x =-1; x <= 1; x+=2 ) {
        var light = new THREE.DirectionalLight( 0xFFFFFF, 1.0 );
        light.position.set( 500 * x, 400 * y, 500 );
        this.scene.add(light);
      }
    }
    
    var axisHelper = new THREE.AxisHelper( 50 );
    this.scene.add( axisHelper );
    
    this.airwayRoot = new THREE.Object3D();
    this.scene.add( this.airwayRoot );
    
    function makeMaterialFromColor( color, name ) {
      return new THREE.MeshPhongMaterial( 
          { 'color': color,
            'name': name,
            specular: 0x111111,
            shininess: 1,
            opacity: 1.0,
            transparent: true } );
    }
    
    function generateContour( startColorCode, endColorCode, steps, inclusive ) {
      if( inclusive === undefined ) inclusive = true;
      
      colors = [];
      startColor = new THREE.Color();
      endColor = new THREE.Color();
      startColor.set( startColorCode );
      endColor.set( endColorCode );
      
      for( var step = (inclusive ? 0 : 1); step < steps; ++step ) {
        var color = startColor.clone();
        color.lerp( endColor, step / steps );
        colors.push( color );
      }
      
      return colors;
    }
    
    // 7-step contour based on 4Dx style-guide
    contours = generateContour( 0x0c4268, 0x027f96, 4 );
    contours = contours.concat( generateContour( 0x027f96, 0x33bbb3 , 4, false ) );
    
    this.contours = contours;
    // Quick test
    legend = $( 'div#legend' );
    for( var index = 0; index < contours.length; ++index ) {
      var item = $( '<div />', {
        'class': 'item',
        'style': 'background-color: ' + contours[ index ].getStyle()  
      });
      
      item.appendTo( legend );
    }
  
    this.material = makeMaterialFromColor( 0x0c4268, 'Base' );
    
    this.selectionMaterials = Array();
    this.selectionColors = [ 0x9e00ff, 0xff0000, 0xffcc00 ];
    for( var material = 0; material < this.selectionColors.length; ++material ) {
      this.selectionMaterials.push( makeMaterialFromColor(  this.selectionColors[ material ], 'Selection ' + material ) );
    }
    
    this.contourMaterials = [];
    for(  index = 0; index < this.contours.length; ++index ) {
      this.contourMaterials.push( makeMaterialFromColor( this.contours[ index ], 'Contour ' + index ) );
    }
    
    
    this.airwayMap = {};
    
    this.camera.position.z = 500;
    this.camera.lookAt( new THREE.Vector3( 0, 0, 1 ) );
    this.camera.updateProjectionMatrix();
    
    this.needsUpdate = true;
    
    this.loadJSON = function( data, status, jqXHR ) {
      var jsonLoader = new THREE.JSONLoader( true );
      var geo = jsonLoader.parse( data );
      
      var airway = new THREE.Mesh( geo.geometry, this.material );
      
      airway.userData = { 'airway': data.id };
      
      this.airwayRoot.add( airway );
      
      this.update();
    };
    
    this.loadBinary = function( data ) {
      var view = new DataView( data );
      var littleEndian = false;
      
      var geometry = new THREE.Geometry();
      
      var offset = 0;
      var version = view.getInt16( 0, littleEndian );
      offset += 2;
      var id = view.getInt32( 2, littleEndian );
      offset += 4;
      
      
      function readV3Floats() {
        var size = view.getInt32( offset, littleEndian );
        offset += 4;
        var list = [];
        
        for( vector = 0; vector < size; ++vector ) {
          var vec = new THREE.Vector3();
          vec.x = view.getFloat32( offset + 0, littleEndian );
          vec.y = view.getFloat32( offset + 4, littleEndian );
          vec.z = view.getFloat32( offset + 8, littleEndian );
          
          offset += 3 * 4;
          
          list.push( vec );
        }
        
        return list;
      }
      
      var normals = readV3Floats();
      geometry.vertices = readV3Floats();
      
      var numFaces = view.getInt32( offset, littleEndian );
      offset += 4;
      
      for( face = 0; face < numFaces; ++face ) {
        var a = view.getInt16( offset + 0, littleEndian );
        var b = view.getInt16( offset + 2, littleEndian );
        var c = view.getInt16( offset + 4, littleEndian );
        offset += 3 * 2;
        
        var face3 = new THREE.Face3( a, b, c, [ normals[ a ], normals[ b ], normals[ c ] ] );
        
        geometry.faces.push( face3 );
      }
      
      var airway = new THREE.Mesh( geometry, this.material );
      airway.userData = { 'airway': id };
      
      this.airwayMap[ id ] = airway;
      this.airwayRoot.add( airway );
    };
    
    this.resizeCanvas = function() {
      this.camera.aspect = this.container.width() / this.container.height()  ;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize( this.container.width(), this.container.height() );
      
      this.update();
    };
    
    this.dispose = function() {
      
      var genericDispose = function( object3d ) {
        if( object3d.geometry !== undefined ) {
          object3d.geometry.dispose();
        }
      };
      
      this.scene.remove( this.airwayRoot );
      
      this.airwayRoot.traverse( genericDispose );
      
      this.airwayRoot = new THREE.Object3D();
      this.scene.add( this.airwayRoot );
    };
    
    this.update = function() {
      this.needsUpdate = true;
    };
    
    this.render = function( forceUpdate ) {
      forceUpdate = (forceUpdate === undefined ) ? false : forceUpdate;
      
      if( this.needsUpdate || forceUpdate ) {
        this.needsUpdate = false;
        this.renderer.render( this.scene, this.camera );
      }
    };
  }
  
  return Scene;
});

