/**
 * 
 */

define( 'ProgressBar',[ 'jquery' ], function( $ ) {
  function ProgressBar()
  {
    this.domObject = $( $.parseHTML( 
      '<div class="progressBar">' +
         '<h3 id="title"></h3>' +
         '<div id="bar">' +
           '<div id="progress"></div>' +
         '</div>' +
         '<div id="text"></div>' +
      '</div>' 
    ) );
    
    this.progress = 0;
    this.total = 0;
    this.percentage = 0;
    
    this.open = function( title, total ) {
      this.domObject.find( '#title' ).text( title );
      
      $( document.body ).append( this.domObject );
      this.domObject.show();
      
      this.total = total;
      this.update();
    };
    
    this.update = function( progress ) {
      if( progress !== undefined ) {
        this.progress = progress;
      }
      
      var percentage = Math.ceil( this.progress / this.total * 100 );
      
      if( percentage != this.percentage ) {
        this.percentage = percentage;
        this.domObject.find( '#progress' ).css( 'width', this.percentage + "%" );
        this.domObject.find( '#text' ).text( this.percentage +" %" );
      }
    };
    
    this.close = function() {
      this.domObject.hide();
      this.domObject.detach();
    };
  }
  
  return ProgressBar;
} );

