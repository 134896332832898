
 
requirejs.config({
    paths: { 
      app: '.',
      jquery: '../lib/jquery/jquery',
      flot: '../lib/flot/jquery.flot',
      'threejs': '../lib/three.js/three',
      'OrbitControls': './OrbitControls'
    },
    shim: {
        "jquery": { "exports": "$" },
        "threejs": { 'exports': 'THREE' },
        'OrbitControls': {
          exports: 'THREE.OrbitControls', 
          deps: [ 'threejs' ]
        },
        'flot': [ 'jquery' ]
    },

});

requirejs( ['app/app'] );


define("main", function(){});

